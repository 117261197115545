<template>
    <div>
        <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="mr-2">
                <v-btn
                    depressed
                    dark
                    color="blue-grey darken-1"
                    @click="editContext('relatorio_repasses','Relatório de repasses (I.R.)')"
                    :class="$css_button"
                    block
                >
                    Relatório de repasses com I.R.
                </v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"  class="mr-2">
                <v-btn
                    depressed
                    dark
                    color="blue-grey darken-1"
                    @click="editContext('relatorio_pagamentos','Relatório de pagamentos do locatário')"
                    :class="$css_button"
                    block
                >
                    Relatório de pagamentos do locatário
                    <v-chip
                        class="ma-2"
                        x-small
                        color="pink"
                        label
                        dark
                    >
                        novo
                    </v-chip>
                </v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="mr-2">
                
            </v-col>  
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="mr-2">
                
            </v-col>    
        </v-row>

        <!-- contexto  -->
        <v-dialog
            v-model="dialog"
            fullscreen
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card
                class="pa-2"
            >
                <v-card-title class="text-h5">
                    {{ title }}

                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
            
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <report-transfer-owner-component v-if="context == 'relatorio_repasses'" :object="object"></report-transfer-owner-component> 
                            <report-tenant-payment-component v-if="context == 'relatorio_pagamentos'" :object="object"></report-tenant-payment-component>  
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>
        </v-dialog>
        <!-- fim contexto  -->

    </div>
</template>

<script>
import ReportTenantPaymentComponent from './ReportTenantPaymentComponent.vue'
import ReportTransferOwnerComponent from './ReportTransferOwnerComponent.vue'

export default {
    components: {
       ReportTransferOwnerComponent,
       ReportTenantPaymentComponent
    },

    props: {
        object: {
            require: true,
        },
    }, 

    data() {
        return {
            title: '',
            context: '',
            dialog: false,
        }
    },

    methods: {
        openCloseDialog(){
            this.dialog = !this.dialog

            if(!this.dialog) this.context = ''
        },

        editContext(context, title){
            this.title = title
            this.context = context
            this.dialog = true
        },
    }
}
</script>