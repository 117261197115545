<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
        <menu-navigation-component></menu-navigation-component>
    </v-navigation-drawer>

    
    <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="primary"
    dark
    >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-toolbar-title
            style="width: 300px"
            class="ml-0 pl-4 sm-text-center"
        >
            <span class="text-center">
                <v-avatar tile size="140">
                    <v-img 
                        class="center"
                        src="img/logo_inner.png"
                        lazy-src="img/logo_inner.png"
                    >
                    </v-img>
                </v-avatar>
            </span>
        </v-toolbar-title>  
    </v-app-bar>

    <v-main class="pa-4" :style="$css_back_ground_color">  
        <router-view></router-view>
    </v-main>


    <v-footer app inset color="primary">
        <v-row dense>
            <v-col cols="12" class="text-center white--text caption">
                {{ new Date().getFullYear() }} — AYT Real State V {{ version }}
            </v-col>
        </v-row>
    </v-footer>

    <loading-component></loading-component>
    <vue-snotify></vue-snotify>
  </v-app>
</template>

<script>
import { CSS } from '../config/config.js'
import MenuNavigationComponent from './MenuNavigationComponent.vue'
import LoadingComponent from '../layouts/LoadingComponent.vue'
import packageJson from '../../package.json'; 

export default {
    
    components: {
        MenuNavigationComponent,
        LoadingComponent,
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    },

    data () {
        return {
            version: packageJson.version,

            paramshome: {
                type: '',
                filter_home: ''
            },
            
            items: {
                search_types: ['FAC','Imóvel','Condomínio','Construtora']
            },

            drawer: null,
            css: CSS,
            mask: {
                phone: '(##) ####-####'
            }
        }
    },

    methods: {
        
    }
}
</script>

<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s
  }

  .fade-enter, .fade-leave-active {
    opacity: 0
  }

  .inblock {
      display: inline-block;
      margin-right: 1.0em;
  }

  .pre-formatted {
    white-space: pre;
  }

  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

    #table_border {
        border-top: 1px solid #757575;
        border-bottom: 1px solid #757575;
        border-left: 1px solid #757575;
        border-right: 1px solid #757575;
    }

    #td_border {
        border-right: 1px solid #d0d0d0; 
    }

    .scroll {
        overflow-y: scroll
    }

    a:link {
        color: black;
        text-decoration: none;
    }

    a:visited {
        color: black;
        text-decoration: none;
    }

    a:hover {
        color: black;
        text-decoration: none;
    }

    a:active {
        color: black;
        text-decoration: none;
    }
</style>