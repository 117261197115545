<template>
    <div>
        <alert-component :alert="alerts.limit"></alert-component>   

        <v-card
            elevation="0"
            class="pa-3 rounded-0"
        >   
            <v-form ref="form" class="p-2">
                <v-row dense>                    
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-select
                            v-model="item.year_transfer_owner_date"
                            :items="items.years"
                            :rules="[rules.required]"
                            outlined
                            :class="$css_field_large"  
                        >
                            <template v-slot:label>
                                <span :class="$css_field_large">Mês / Ano</span>
                            </template>
                        </v-select> 
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <v-btn
                            x-large
                            depressed
                            color="secondary"
                            @click.prevent="validate()"
                            :class="$css_button_large"
                        >
                            Enviar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>            
        </v-card> 

        <v-card
            elevation="0"
            class="pa-3 rounded-0"
            v-if="reportData && reportData.length > 0"
        >  
            <v-row class="text-right">
                <v-spacer></v-spacer>
                <v-col>
                    <v-btn
                        depressed
                        color="secondary"
                        @click.prevent="loadRentalMonthlyFeeReportPdf()"
                        :class="$css_button_large"
                    >
                        Exportar PDF
                    </v-btn>
                </v-col>
            </v-row>
            
            <v-data-table
                :headers="headers"
                :items="reportData"
                class="elevation-1 table-striped caption mt-2"
                disable-pagination
                hide-default-footer
            >

                <template v-slot:[`item.due_date`]="{ item }">
                    <v-chip
                        :color="statusDueDate(item)"    
                        label
                    >
                        {{ formatDate(item.due_date) }}
                    </v-chip>               
                </template>

                <template v-slot:[`item.transfer_owner_date`]="{ item }">
                    <v-chip
                        v-if="item.transfer_owner_date"
                        label
                    >
                        {{ formatDate(item.transfer_owner_date) }}
                    </v-chip>
                </template>

                <template v-slot:[`item.valor_aluguel`]="{ item }">
                    <span :class="$css_show_title">
                        {{ extractRentalMonthlyFeeValues(item.json_rental_monthly_fee_values, 'charge', 'Aluguel', transfer_to = null) }}
                    </span>
                </template>

                <template v-slot:[`item.encargos`]="{ item }">
                    <span :class="$css_show_title">
                        {{ extractDescriptionRentalMonthlyFeeValues(item.json_rental_monthly_fee_values,'encargos') }}
                    </span>
                </template>

                <template v-slot:[`item.descontos`]="{ item }">
                    <span :class="$css_show_title">
                        {{ extractDescriptionRentalMonthlyFeeValues(item.json_rental_monthly_fee_values,'descontos') }}
                    </span>
                </template>

                <template v-slot:[`item.valor_adm`]="{ item }">
                    <span :class="$css_show_title">
                        {{ extractRentalMonthlyFeeValues(item.json_rental_monthly_fee_values, 'adm', null, null) }}
                    </span>
                </template>

                <template v-slot:[`item.total_owner`]="{ item }">
                    <span :class="$css_show_title">
                        {{ totalOwner(item.json_rental_monthly_fee_values) }}
                    </span>
                </template>

            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import AlertComponent from '@/components/alert/AlertComponent.vue'

export default {
    components: {
        AlertComponent,
    },

    props: {
        object: {
            require: true,
        },
    }, 

    data () {
        return {
            alerts: {
                limit: {
                    show: '',
                    type: 'warning',
                    icon: 'mdi-alert-outline',
                    messages: ['O relatório poderá exibir até 5 anos retroativos à data atual. Para períodos diferentes, entre em contato com a AYT.']
                },
            },

            item: {
               paginate: false,
               title: 'Relatório de Repasses',
               year_transfer_owner_date: this.getCurrentYear() ,
               rental_id: this.object.id
            },

            items: {
                years: this.generateDateOptions()
            },

            payment_done: false,

            rules: {
                required: value => !!value || 'Obrigatorio',
                min_value:  v => (v && v.length >= 6) || 'O valor precisa ser maior que R$ 100',
            },

            reportData: [],

            headers: [
                { text: 'DT. VENC.', value: 'due_date' , sortable: false},
                { text: 'DT. REPASSE.', value: 'transfer_owner_date' , sortable: false},
                { text: 'VL. ALUGUEL', value: 'valor_aluguel' , sortable: false},
                { text: 'ENCARGOS', value: 'encargos' , sortable: false},
                { text: 'DESCONTOS', value: 'descontos' , sortable: false},
                { text: 'VL. ADM', value: 'valor_adm', sortable: false },
                { text: 'VL. LIQUIDO', value: 'total_owner', sortable: false },
            ],
            
        }       
    },

    watch: {
        payment_done () {
            this.filterPaymentDone()
        },
    },
    
    methods: {
        generateDateOptions() {
            const options = [];
            const today = new Date();
            const currentYear = today.getFullYear();
            const totalYear = 5; // 5 anos

            for (let i = 0; i < totalYear; i++) {
                let year = currentYear - i;
                options.push(`${year}`);
            }

            return options;
        },

        getCurrentYear() {
            const today = new Date();
            const year = today.getFullYear();
            return `${year}`;
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.submit(this.item)
            }
        },

        submit (item){
            this.$store.dispatch('loadPromisseRentalMonthlyFees', item)
                        .then(response => {
                            this.reportData = response.data
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        },

        loadRentalMonthlyFeeReportPdf(){
            const params = {}
            params.title = 'Relatório de Repasses';
            params.paginate = 'false';
            params.rental_id = this.object.id;
            params.year_transfer_owner_date = this.item.year_transfer_owner_date;
            params.file_name = 'Cod-' + this.object.codigo + '-' + params.title + '-' + params.year_transfer_owner_date  + '.pdf';
            this.$store.dispatch('loadRentalMonthlyFeeReportPdf', params) 
        },

    }
}
</script>