var moment = require('moment')

import { URL_UPLOAD } from '../config/config.js'
import { PROPERTY_ITEMS } from '../config/property_items/property_items.js'

export default {

    firstDayOfMonth(){
        var result = moment().format("01/MM/YYYY")
        return result
    },

    lastDayOfMonth(){
        var result = moment().daysInMonth() + moment().format("MM/YYYY")
        return result
    },
    
    statusOpenClosed (value) {
        if(!value) return ''
        if(value == 'Open') return 'red'
        else if(value == 'Closed') return 'green'
        else return ''
    },

    statusDueDate (item) {
        if(!item.due_date) return ''

        let color = '';
        var date = moment(item.due_date)
        var now = moment()

        const diff = now.diff(date, 'days')

        if (diff > 0) {
            color = '#F8BBD0'
        }else{
            color = '#FFE0B2'
        }
        
        if(item.payment_date != null){
            color = '#DCEDC8'
        }
        return color
    },

    reportTotalAdm(item){
        if(!item || item.length == 0) return 'R$ ' + 0
        
        var total = 0 

        item.forEach(i => {
            let valor = this.totalAdm(i.json_rental_monthly_fee_values);
            valor = valor.replace(/[^\d,.-]/g, "");
            valor = parseFloat(valor.replace(",", ".")); 
            total += valor;
        })

        return 'R$ ' + total.toFixed(2)
    },
    
    
    extractRentalMonthlyFeeValues(jsonItem, type, description = null, transfer_to = null){
        if(!jsonItem) return 'R$ ' + 0
        var total = 0 
        var results = [];
        
        if(description != null){
            results = jsonItem.filter(model => model.type == type && model.description == description)
        }else{
            results = jsonItem.filter(model => model.type == type)
        }
                
        if(results.length > 0){
            results.forEach(i => {
                total += i.value
            })
        }
        
        return 'R$ ' + total.toFixed(2)
    },

    extractDescriptionRentalMonthlyFeeValues(jsonItem, type, except_transfer_to = null){
        if(!jsonItem) return 'R$ ' + 0
        var description = ''; 
        var results = [];

        if(type == 'encargos'){
            results = jsonItem.filter(model => model.type == 'charge' && model.description != 'Aluguel')
        }

        if(type == 'descontos'){
            if(except_transfer_to && except_transfer_to != ''){
                results = jsonItem.filter(model => model.type == 'discount' && model.transfer_to != except_transfer_to)
            } else {
                results = jsonItem.filter(model => model.type == 'discount')
            }
        }

        if(results.length > 0){
            results.forEach(i => {
                description += i.description + ': R$ ' + i.value.toFixed(2) + ' '
            })
        }
        
        return description
    },
    
    
    totalAdm( jsonItem){
        if(!jsonItem) return 'R$ ' + 0
        var total = 0 
        var results = jsonItem.filter(model => model.type == 'adm')
        if(results.length > 0){
            results.forEach(i => {
                total += i.value
            })
        }

        var totalCharge = 0 
        var resultCharge = jsonItem.filter(model => model.type == 'charge' && model.transfer_to == 'Imobiliária')
        if(resultCharge.length > 0){
            resultCharge.forEach(i => {
                totalCharge += i.value
            })
        }

        total += totalCharge

        return 'R$ ' + total.toFixed(2)
    },

    totalOwner(jsonItem){
        if(!jsonItem) return 'R$ ' + 0

        var totalCharge = 0 
        var resultCharge = jsonItem.filter(model => model.type == 'charge' && model.transfer_to == 'Locador')
        if(resultCharge.length > 0){
            resultCharge.forEach(i => {
                totalCharge += i.value
            })
        }

        var totalDiscount = 0 
        var resultDiscount = jsonItem.filter(model => model.type == 'discount' && model.transfer_to != 'Locatário')
        if(resultDiscount.length > 0){
            resultDiscount.forEach(i => {
                totalDiscount += i.value
            })
        }

        var totalAdm = 0 
        var results = jsonItem.filter(model => model.type == 'adm')
        if(results.length > 0){
            results.forEach(i => {
                totalAdm += i.value
            })
        }


        var total = totalCharge - totalDiscount - totalAdm;
        return 'R$ ' + total.toFixed(2)
    },
    
    
    totalTenant(jsonItem){
        if(!jsonItem) return 'R$ ' + 0

        var totalCharge = 0 
        var resultCharge = jsonItem.filter(model => model.type == 'charge')
        if(resultCharge.length > 0){
            resultCharge.forEach(i => {
                totalCharge += i.value
            })
        }

        var totalDiscount = 0 
        var resultDiscount = jsonItem.filter(model => model.type == 'discount' && model.transfer_to != 'Locador')
        if(resultDiscount.length > 0){
            resultDiscount.forEach(i => {
                totalDiscount += i.value
            })
        }

        var total = totalCharge - totalDiscount
        return 'R$ ' + total.toFixed(2)
    },

    copyPaste(text){
        navigator.clipboard.writeText(text);
    },

    onlyNumber(str) {
        return str.replace(/[^0-9]+/g, '')
    },

    urlUpload(param){
        return URL_UPLOAD + param
    },

    formatDate (date) {
        if(date != '' && date != null){
            return moment(String(date)).format( 'DD/MM/YYYY')
        }else{
            return ''
        }  
    },

    formatDateTime (date) {
        if(date != '' && date != null){
            return moment(String(date)).format('DD/MM/YYYY H:m:s')
        }else{
            return ''
        }  
    },

    formatDate2 (date) {
        if(date != '' && date != null){
            return moment(String(date)).format('YYYYMMDD')
        }else{
            return ''
        }  
    },

    

    filterPropertyItems(search){
        if(search.model && search.model != '' && search.model != undefined){
            var results = PROPERTY_ITEMS.filter(model => model.model == search.model)
            if(search.tipos && search.tipos != ''){
                var tipos = []
                results.forEach(i => {
                    var item = i.tipos.includes(search.tipos)
                    if(item == true){
                        tipos.push(i)
                    }
                })
                results = tipos 
            }

            
            if(search.regioes && search.regioes != '' && search.regioes != 'Não informado'){
                var regioes = []
                results.forEach(i => {
                    var item = i.regioes.includes(search.regioes)
                    if(item == true){
                        regioes.push(i)
                    }
                })
                results = regioes 
            } 


            // order
             
            var ordem = 'order'
            if(search.order && search.order != ''){
                ordem = search.order
            }

            if(ordem == 'description'){
                return results.sort(function(a, b){
                    if(a.description > b.description) { return 1 }
                    if(a.description < b.description) { return -1 }
                    return 0
                })
            }
    
            if(ordem == 'order'){
                return results.sort((a, b) =>
                    a.order-b.order
                )
            }
            
        }
    },

    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    converteObjectArrayToString(object){
        let string = '';
        object.forEach((value, index) => {
            if(string != ''){
                string += ', '
            }
            string += value.description
        })
        return string
    },

    converteArrayToString(array){
        let string = '';
        array.forEach(value => {

            if(string != ''){
                string += ', '
            }
            string += value
        })
        return string
    },

    converteStringToArray(value, separator){
        if(!value && value != '') return ''
        return value.split(separator)
    },

    maskPhone(phone){
        var mask = phone.length > 10 ? '(##) #####-####' : '(##) ####-####'
        return mask
    },

    userPermission(description){
        if(this.me.profile != 'Master' && this.me.profile != 'Gerente'){
            var result = this.me.permissions.filter(model => model.description == description)
            if(result && result.length > 0){
                return true
            }else{
                return false
            }
        }else{
            return true
        }
    }

}