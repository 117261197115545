<template>
    <div>
        <v-card flat class="pa-8">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="mr-2">
                    <v-btn
                        depressed
                        dark
                        color="blue-grey darken-1"
                        @click="editContext('previsao_receita', 'Previsão de Receitas')"
                        :class="$css_button"
                        block
                    >
                        Previsão de receitas
                    </v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"  class="mr-2">
                
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="mr-2">
                    
                </v-col>  
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="mr-2">
                    
                </v-col>    
            </v-row>
        </v-card>

        <!-- contexto  -->
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card
                class="pa-2"
            >
                <v-card-title class="text-h5">
                    {{ title }}

                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
            
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <adm-commission-component v-if="context == 'previsao_receita'"></adm-commission-component> 
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>
        </v-dialog>
        <!-- fim contexto  -->
    </div>
</template>

<script>
import AdmCommissionComponent from './AdmCommissionComponent'

export default {
    components: {
        AdmCommissionComponent
    },

    data() {
        return {
            title: '',    
            context: '',
            dialog: false,
        }
    },

    methods: {
        openCloseDialog(){
            this.dialog = !this.dialog

            if(!this.dialog) this.context = ''
        },

        editContext(context, title){
            this.title = title
            this.context = context
            this.dialog = true
        },
    }
}
</script>