<template>
    <div>
        <alert-component :alert="alerts.limit"></alert-component>   

        <v-card
            elevation="0"
            class="pa-3 rounded-0"
        >   
            <v-form ref="form" class="p-2">
                <v-row dense>                    
                    <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <v-select
                            v-model="item.due_date_month_year"
                            :items="items.due_date_month_year"
                            :rules="[rules.required]"
                            outlined
                            :class="$css_field_large"  
                        >
                            <template v-slot:label>
                                <span :class="$css_field_large">Mês / Ano</span>
                            </template>
                        </v-select> 
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <v-btn
                            x-large
                            depressed
                            color="secondary"
                            @click.prevent="validate()"
                            :class="$css_button_large"
                        >
                            Pesquisar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>            
        </v-card> 

        <v-card
            elevation="0"
            class="pa-3 rounded-0"
            v-if="reportData && reportData.length > 0"
        >  
            <v-row>
                <v-col>
                    <v-card-title>Total ADM: {{ reportTotalAdm(reportData) }}</v-card-title>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                    <v-checkbox
                        v-model="payment_done"
                        outlined
                        :class="$css_field"
                        @keydown.enter="payment_done = !payment_done"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Filtra mensalidades pagas</span>
                        </template>
                    </v-checkbox>
                </v-col>
                <v-col>
                    <v-btn
                        depressed
                        color="secondary"
                        @click.prevent="loadRentalMonthlyFeeReportPdf()"
                        :class="$css_button_large"
                    >
                        Exportar PDF
                    </v-btn>
                </v-col>
            </v-row>
            


            <v-data-table
                :headers="headers"
                :items="reportData"
                class="elevation-1 table-striped caption"
                disable-pagination
                hide-default-footer
            >
                <template v-slot:[`item.details`]="{ item }">
                    <div class="mt-2 mb-2">
                        <div :class="$css_show_title">
                            <v-chip
                                dark
                                :color="item.codigo | chipDefaultColor"
                                label
                                small
                                :class="$css_button + ' mr-2'"
                            >
                            CODIGO  {{ item.codigo }}
                            </v-chip>

                            <v-chip
                                v-if="item.pasta"
                                dark
                                color="red"
                                label
                                small
                                :class="$css_button + ' mr-2'"
                            >
                            PASTA  {{ item.pasta }}
                            </v-chip>

                            <span v-if="item.tipo_imovel">({{ item.tipo_imovel }})</span>
                            {{ item.endereco }}

                            <span v-if="item.numero">, {{ item.numero }}</span>
                            <span v-if="item.apartamento"> APTO:  {{ item.apartamento }}</span>
                            <span v-if="item.complemento"> Compl:  {{ item.complemento }}</span>
                        </div>
                    </div> 
                </template>


                <template v-slot:[`item.due_date`]="{ item }">
                    <v-chip
                        :color="statusDueDate(item)"    
                        label
                    >
                        {{ formatDate(item.due_date) }}
                    </v-chip>               
                </template>

                <template v-slot:[`item.payment_date`]="{ item }">
                    <v-chip
                        v-if="item.payment_date"
                        label
                    >
                        {{ formatDate(item.payment_date) }}
                    </v-chip>
                </template>

                <template v-slot:[`item.valor_tenant`]="{ item }">
                    <span :class="$css_show_title">
                        {{ totalTenant(item.json_rental_monthly_fee_values) }}
                    </span>
                </template>

                <template v-slot:[`item.valor_adm`]="{ item }">
                    <span :class="$css_show_title">
                        {{ totalAdm(item.json_rental_monthly_fee_values) }}
                    </span>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import AlertComponent from '@/components/alert/AlertComponent.vue'

export default {
    components: {
        AlertComponent,
    },

    data () {
        return {
            alerts: {
                limit: {
                    show: '',
                    type: 'warning',
                    icon: 'mdi-alert-outline',
                    messages: ['O relatório poderá exibir até 5 anos retroativos à data atual. Para períodos diferentes, entre em contato com a AYT.']
                },
            },

            item: {
               paginate: false,
               due_date_month_year: this.getCurrentMonthYear() 
            },

            items: {
                due_date_month_year: this.generateDateOptions()
            },

            payment_done: false,

            rules: {
                required: value => !!value || 'Obrigatorio',
                min_value:  v => (v && v.length >= 6) || 'O valor precisa ser maior que R$ 100',
            },

            originalData: [],
            reportData: [],

            headers: [
                { text: 'DADOS LOCACAO', value: 'details' , sortable: false},
                { text: 'DT. VENC.', value: 'due_date' , sortable: false},
                { text: 'DT. PGTO.', value: 'payment_date' , sortable: false},
                { text: 'VL. MENS.', value: 'valor_tenant' , sortable: false},
                { text: 'VL. ADM', value: 'valor_adm', sortable: false },
            ],
            
        }       
    },

    watch: {
        payment_done () {
            this.filterPaymentDone()
        },
    },
    
    methods: {
        generateDateOptions() {
            const options = [];
            const today = new Date();
            const currentYear = today.getFullYear();
            const currentMonth = today.getMonth() + 1; // getMonth() retorna de 0 a 11
            const pastMonths = 5;  // Quantidade de meses no passado
            const futureMonths = 6; // Quantidade de meses no futuro
            const totalMonths = pastMonths + futureMonths + 1; // +1 para incluir o mês atual

            for (let i = -pastMonths; i <= futureMonths; i++) {
                let date = new Date(currentYear, currentMonth - 1 + i, 1);
                let month = String(date.getMonth() + 1).padStart(2, "0"); // Sempre com dois dígitos
                let year = date.getFullYear();
                options.push(`${month}/${year}`);
            }

            return options;
        },


        getCurrentMonthYear() {
            const today = new Date();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Garantindo dois dígitos
            const year = today.getFullYear();
            return `${month}/${year}`;
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.submit(this.item)
            }
        },

        submit (item){
            this.payment_done = false;
            this.reportData = []
            this.$store.dispatch('loadPromisseRentalMonthlyFees', item)
                        .then(response => {
                            this.originalData = response.data
                            this.reportData = this.originalData 
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        },

        filterPaymentDone(){
            if(this.payment_done){
                let results = this.reportData.filter(item => item.payment_date !== null);
                this.reportData = results;
            }else{
                this.reportData = this.originalData;
            }
        },

        loadRentalMonthlyFeeReportPdf(){
            const params = {}
            params.title = 'Previsão de Receita';
            params.paginate = 'false';
            params.due_date_month_year = this.item.due_date_month_year;
            params.file_name = params.title + '-' + params.due_date_month_year  + '.pdf';
            this.$store.dispatch('loadRentalMonthlyFeeReportPdf', params) 
        },

    }
}
</script>